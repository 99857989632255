<template>
  <div class="UnreadMeter">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="can('meter-reading-create')"
          color="primary"
          class="mb-5"
          @click="showAddUnreadMeterDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiShapeSquarePlus }}
          </v-icon>
          Add Unread Meter
        </v-btn>
      </v-col>
    </v-row>
    <unread-meters-table
      :meter-reading-url="meterReadingUrl"
      :show-add-meter-dialog="showAddUnreadMeterDialog"
      @add-meter-dialog-open="showAddUnreadMeterDialog = true"
      @add-meter-dialog-close="showAddUnreadMeterDialog = false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiShapeSquarePlus,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import UnreadMetersTable from '@/components/tables/UnreadMetersTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'unread-meters-table': UnreadMetersTable,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddUnreadMeterDialog: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Unread Meters',
          disabled: true,
          to: { name: 'unread-meters' },
        },
      ],
      icons: {
        mdiShapeSquarePlus,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    meterReadingUrl() {
      return `unread-meters?station_id=${this.selectedStation}`
    },
  },
}
</script>
